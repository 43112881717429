import React, { useState, useEffect, forwardRef, useMemo } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {MDBBtn,MDBInput,MDBIcon} from "mdb-react-ui-kit";
import MobiledataOff from '@mui/icons-material/MobiledataOff';
import {roundNumber,formatAMPM} from "./Util";
import { Box, IconButton } from "@mui/material";
import {dataTipo} from "./Services";
import { Input, Label,Row,Col } from "reactstrap";
import {imgURLocalImage} from "../Componentes/Services";
export const COLUMNS_BODEGA = [
  {
    accessorKey: "bod_nombre",
    header: "NOMBRE",
    grow:false,
    Edit: () => null,
    enableEditing: false
  },
  {
    accessorKey: "bod_ubicacion", //normal accessorKey
    header: "UBICACIÓN",
    size: 200,
    grow:false,
    Edit: () => null,
    enableEditing: false
  },
];
export const COLUMNS_CLIENTES = [
  {
    accessorKey: "cli_nombre",
    header: "NOMBRE",
    size: 350,
  },
  {
    accessorKey: "cli_telefono", //normal accessorKey
    header: "TELÉFONO",
    size: 100,
  },
  {
    accessorKey: "cli_ruc", //normal accessorKey
    header: "RUC",
    size: 100,
  },
  {
    accessorKey: "cli_limitc", //normal accessorKey
    header: "LÍMITE DE CRÉDITO",
    size: 100,
  },
];
export const COLUMNS_PROVEEDORES = [
  {
    accessorKey: "prov_nombre",
    header: "NOMBRE",
    size: 350,
  },
  {
    accessorKey: "prov_telefono", //normal accessorKey
    header: "TELÉFONO",
    size: 100,
  },
  {
    accessorKey: "prov_ruc", //normal accessorKey
    header: "RUC",
    size: 100,
  },
];
export const COLUMNS_MARCAS = [
  {
    accessorKey: "mar_descripcion",
    header: "NOMBRE",
    size: 350,
  },
];
export const COLUMNS_CATEGORIAS = [
  {
    accessorKey: "cat_descripcion",
    header: "NOMBRE",
    size: 350,
  },
];
export const COLUMNS_USUARIOS = [
  {
    accessorKey: "usr_usuario",
    header: "USUARIO",
  },
  {
    accessorKey: "usr_nombre",
    header: "NOMBRE",
  },
  {
    accessorFn: (row) => `${dataTipo[row.usr_perfil] !== undefined?dataTipo[row.usr_perfil].label:null}`,
    accessorKey: "usr_perfil",
    header: "TIPO",
    size: 350,
  },
];
export const COLUMNS_ARTICULOS = [
  {
    accessorKey: "art_upc",
    header: "CÓDIGO",
    size: 150,
    muiTableHeadCellProps: {
      align: 'center',
    },
  },

  {
    accessorKey: "art_descripcion",
    header: "DESCRIPCIÓN",
    size: 350,
    muiTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "inv_qty",
    header: "CANTIDAD",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_costo",
    header: "COSTO",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_precio",
    header: "PRECIO",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_precio2",
    header: "PRECIO 2",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_precio3",
    header: "PRECIO 3",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "cat_descripcion",
    header: "CATEGORIA",
    size: 50,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "prov_nombre",
    header: "PROVEEDOR",
    size: 50,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "mar_descripcion",
    header: "MARCA",
    size: 30,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_referencia",
    header: "REFERENCIA",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_serie",
    header: "# SERIE",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_linea_roja",
    header: "LÍNEA ROJA",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: "art_itbm_venta",
    header: "ITBM VENTA",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    accessorFn: (row) => `${(Number(row.art_itbm_venta)*100).toFixed(2)+"%"}`,
  },
  {
    accessorKey: "art_itbm_compra",
    header: "ITBM COMPRA",
    size: 20,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    accessorFn: (row) => `${(Number(row.art_itbm_compra)*100).toFixed(2)+"%"}`,
  },
];

export const COLUMNS_REPORT_INFORME_CAJA = [
  {
    accessorKey: "fac_fecha",
    header: "Fecha",
  },
  {
    accessorKey: "cli_nombre",
    header: "Cliente",
  },

  {
    accessorKey: "cli_ruc",
    header: "RUC",
  },
  {
    accessorKey: "usr_nombre",
    header: "Vendedor",
  },
  {
    accessorKey: "fac_caja",
    header: "Caja",
    // size: 450,
  },
  {
    accessorKey: "fac_motivo_anulacion",
    header: "Motivo",
    // size: 450,
  },
  {
    accessorKey: "fac_date_anulacion",
    header: "Fecha de anulacion",
    // size: 450,
  },
  {
    accessorKey: "fac_anulacion",
    header: "Anulacion",
    accessorFn: (row) => `${row.fac_anulacion==="1"?"ANULADO":""}`,
    // size: 450,
  },
  {
    accessorKey: "fcd_precio",
    header: "Subtotal",
    // size: 450,
  },
  {
    accessorKey: "fcd_itbms",
    header: "ITBMS",
    // size: 450,
  },
  {
    accessorKey: "fcd_total",
    header: "TOTAL",
    // size: 450,
  }
];

export const COLUMNS_REPORT_VENTA = [
  {
    accessorKey: "fac_id", //access nested data with dot notation
    header: "N° documento",
    accessorFn: (row) => `${row.fac_id.toString().padStart(10, "0")}`,
    // size: 20,
  },
  {
    accessorKey: "fac_fecha",
    header: "Fecha",
    // size: 60,
  },
  {
    accessorKey: "cli_nombre",
    header: "Cliente",
    // size: 170,
  },

  {
    accessorKey: "cli_ruc",
    header: "RUC",
    // size: 40,
  },
  {
    accessorKey: "usr_nombre",
    header: "Vendedor",
    // size: 60,
  },
  {
    accessorKey: "fac_caja",
    header: "Caja",
    // size: 450,
  },
  {
    accessorKey: "fac_motivo_anulacion",
    header: "Motivo",
    // size: 450,
  },
  {
    accessorKey: "fac_date_anulacion",
    header: "Fecha de anulacion",
    // size: 450,
  },
  {
    accessorKey: "fac_anulacion",
    header: "Anulacion",
    accessorFn: (row) => `${row.fac_anulacion==="1"?"ANULADO":""}`,
    // size: 450,
  },
  {
    accessorKey: "fcd_precio",
    header: "Subtotal",
    // size: 450,
  },
  {
    accessorKey: "fcd_itbms",
    header: "ITBMS",
    // size: 450,
  },
  {
    accessorKey: "fcd_total",
    header: "TOTAL",
    // size: 450,
  }
];
export const COLUMNS_AP_APARTADO = [
  {
    accessorKey: "ap_docn", //access nested data with dot notation
    header: "N° documento",
    size: 20,
  },
  {
    accessorKey: "dcd_total",
    header: "Monto",
    size: 60,
  },
  {
    accessorKey: "prov_proveedor",
    header: "Proveedor",
    size: 170,
  },

  {
    accessorKey: "ap_fecha",
    header: "Fecha de documento",
    size: 40,
  },
  {
    accessorKey: "ap_fecha_procesado",
    accessorFn: (row) => `${formatAMPM(new Date(row.ap_fecha_procesado ))}`,
    header: "Fecha de procesado",
    size: 60,
  },
  {
    accessorKey: "ap_descripcion",
    header: "DESCRIPCIÓN",
    size: 450,
  }
];
export const COLUMNS_DC_COMPRA = [
  {
    accessorKey: "dc_docn", //access nested data with dot notation
    header: "N° DOCUMENTO",
    size: 20,
  },
  {
    accessorKey: "dcd_total",
    header: "MONTO",
    size: 60,
  },
  {
    accessorKey: "prov_proveedor",
    header: "PROVEEDOR",
    size: 170,
  },

  {
    accessorKey: "dc_fecha",
    header: "FECHA DE DOCUMENTO",
    size: 40,
  },
  {
    accessorKey: "dc_fecha_procesado",
    accessorFn: (row) => `${formatAMPM(new Date(row.dc_fecha_procesado ))}`,
    header: "FECHA PROCESADO",
    size: 60,
  },
  {
    accessorKey: "dc_descripcion",
    header: "DESCRIPCIÓN",
    size: 450,
  }
];
export function COLUMNS_DC_COMPRA_DETALLE(deleteRow,onBlurs){ 
  return [
    // {
    //   accessorKey: "dcd_id", //access nested data with dot notation
    //   header: "ID",
    //   size: 120,
    //   minSize:100,
    //   // enableEditing: false,
    //   enableHiding: true,
    // },
  {
    accessorKey: "art_upc", //access nested data with dot notation
    header: "CÓDIGO",
    size: 120,
    minSize:100,
    enableEditing: false
  },
  {
    accessorKey: "art_descripcion",
    header: "DESCRIPCIÓN",
    size: 250,
    minSize:200,
    enableEditing: false
  },
  {
    accessorKey: "dcd_precio",
    header: "PRECIO",
    size: 40,
    minSize:40,
    enableEditing: false
  },

  {
    accessorKey: "dcd_cantidad",
    header: "CANTIDAD",
    size: 40,
    minSize:40,
    // Edit: ({ cell, column, row, table }) => {
    //   return <Input className="input-edit-cantidad" type="number" min={1} defaultValue={row.original.dcd_cantidad} onBlur={(event) => onBlurs(event,row)} />;
    // },
  },
  {
    accessorFn: (row) => `${roundNumber(Number(row.dcd_itbms),3)}`,
    accessorKey: "dcd_itbms",
    header: "ITBMS",
    size: 40,
    minSize:40,
    enableEditing: false
  },
  {
    accessorFn: (row) => `${roundNumber(Number(row.dcd_total),3)}`,
    accessorKey: "dcd_total",
    header: "TOTAL",
    size: 60,
    minSize:40,
    enableEditing: false
  },
  // {
  //   accessorKey: 'profileImage',
  //   size: 60,
  //   minSize:40,
  //   header: 'Delete',
  //   enableEditing: false,
  //   Cell: ({ row,cell }) => {
  //     return <Box className="width-box-delete">
  //     <IconButton
  //       color="error"
  //       onClick={() =>
  //         deleteRow(row)
  //       }
  //     >
  //       <DeleteIcon />
  //     </IconButton>
  // </Box>
  //   },
  // },
]};
export function COLUMNS_LISTA_FPAGO(){ 
  return [
    {
      accessorKey: "list_fpagoName",
      header: "FORMA DE PAGO",
      size: 350,
      enableEditing: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "list_monto", //normal accessorKey
      header: "MONTO",
      accessorFn: (row) => `${parseFloat(row.list_monto).toFixed(2)}`,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      size: 100,
    },
]};

export function COLUMNS_LISTA_ANULACION(setTabAnular,setFormAnulacion){ 
  return [
    {
      accessorKey: "fac_id",
      header: "# FACTURA",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      size:70,
      // accessorFn: (row) => `${<Label>{row.fac_id.toString().padStart(10, "0")}</Label>}`,
      Cell: ({ row,cell }) => {
        return <Box className="d-flex">
<MDBIcon fas icon="times-circle" className="me-1 class-btn-factura-datatable" />
        <Label className="class-btn-factura">
            {row.original.fac_id.toString().padStart(10, "0")}
          </Label>
    </Box>
      },
    },
    {
      accessorKey: "cli_nombre",
      accessorFn: (row) => `${row.cli_ruc !==""? row.cli_nombre+ " "  + row.cli_ruc: row.cli_nombre}`,
      header: "NOMBRE",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "fac_fecha",
      accessorFn: (row) => `${formatAMPM(new Date(row.fac_fecha ))}`,
      header: "FECHA",
      size:70,
      enableGlobalFilter: false, 
      enableFilters:false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableGlobalFilter: false, 
      size:50,
      accessorKey: "fac_total", 
      header: "MONTO",
    }
]};


export function COLUMNS_LISTA_FACTURA(selectItemFCopia){ //tipo 0 => recibo, tipo 1 =>electronica
  return [
    {
      accessorKey: "fac_id",
      header: "# FACTURA",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      size:70,
      Cell: ({ row,cell }) => {
        return <Box className="d-flex">
          <MDBIcon fas icon="print" className="me-3 class-btn-factura-datatable" onClick={() =>selectItemFCopia({data:row.original,tipo:row.original.fac_recibo} )}/>
          {/* <MDBIcon fas icon="copy" className="me-3 class-btn-factura-datatable" onClick={() =>selectItemFCopia({data:row.original,tipo:"0"} )}/> */}
          <Label className="class-btn-factura">
              {row.original.fac_id.toString().padStart(10, "0")}
            </Label>
    </Box>
      },
    },
    {
      accessorKey: "cli_nombre",
      accessorFn: (row) => `${row.cli_ruc !==""? row.cli_nombre+ " "  + row.cli_ruc: row.cli_nombre}`,
      header: "NOMBRE",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "fac_fecha",
      accessorFn: (row) => `${formatAMPM(new Date(row.fac_fecha ))}`,
      header: "FECHA",
      size:70,
      enableGlobalFilter: false, 
      enableFilters:false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableGlobalFilter: false, 
      size:50,
      accessorKey: "fcd_total", 
      header: "MONTO",
    }
]};

export function COLUMNS_LISTA_ITEMS(){ 
  return [
    {
      accessorKey: "art_upc",
      header: "CÓDIGO",
      size: 50,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      enableGlobalFilter: true, 
    },
    {
      accessorKey: "art_descripcion", //normal accessorKey
      header: "DESCRIPCIÓN",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      enableGlobalFilter: true, 
    },
    {
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableGlobalFilter: false, 
      accessorKey: "art_precio", //normal accessorKey
      header: "PRECIO",
      size: 50,
    },
    {
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableGlobalFilter: false, 
      accessorKey: "itbm_descripcion", //normal accessorKey
      header: "ITBMS",
      size: 50,
    },
    {
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableGlobalFilter: false, 
      accessorKey: "mar_descripcion", //normal accessorKey
      header: "MARCA",
      size: 50,
    },
]};


export const COLUMNS_COT_COTIZACION =[
    {
      accessorKey: "cot_id", //access nested data with dot notation
      header: "# COTIZACIÓN",
      size: 10,
      minSize:10,
      maxSize:20,
      grow:false,
    },
    {
      accessorKey: "cli_nombre",
      accessorFn: (row) => `${row.cli_nombre+ " / "  + row.cli_ruc}`,
      header: "CLIENTE",
      size: 350,
      minSize:350,
      maxSize:400,
      grow:false,
      Edit: () => null,
      enableEditing: false
    },
    {
      accessorKey: "cot_fecha", //normal accessorKey
      header: "FECHA",
      size: 40,
      minSize:40,
      maxSize:50,
      grow:false,
      Edit: () => null,
      enableEditing: false
    },
    {
      accessorKey: "cotd_total",
      header: "MONTO",
      size: 40,
      minSize:40,
      maxSize:50,
      grow:false,
      Edit: () => null,
      enableEditing: false
    },
]

export function COLUMNS_FAC_FACTURA_DETALLE2(deleteRow,onBlurs){ 
  return [
  {
    accessorKey: 'fac_id',
    // size: 60,
    // minSize:40,
    header: '',
    enableHiding:false,
    enableEditing: false,
    columnVisibility:false,
    Cell: ({ row,cell }) => {
      return <Box className="width-box-delete2">
        <Row>
          <Col xs={6}>
          <Label>
            ARTICULO
          </Label>
          </Col>
          <Col xs={2}>
          <Label>
            CANT
          </Label>
            </Col>
            <Col xs={2}>
            <Label>
              PRECIO
            </Label>
            </Col>
            <Col xs={2}>
            <Label>
              TOTAL
            </Label>
            </Col>
        </Row>
        <Row>
          {
            
          }
        </Row>
      <IconButton
        color="error"
        onClick={() =>
          deleteRow(row)
        }
      >
        <DeleteIcon />
      </IconButton>
  </Box>
    },
  },
]};

export function COLUMNS_ORDENES_DETALLE_RESTAURANTE(usr_precio){ 
  return [
  {
    accessorKey: "art_descripcion",
    header: "DESCRIPCIÓN",
    grow:false,
    // Edit: () => null,
    enableEditing: false,
    
  },
  {
    accessorKey: "ordd_precio",
    header: "PRECIO",
    accessorFn: (row) => `$${parseFloat(row.ordd_precio).toFixed(2)}`,
    size: 40,
    minSize:40,
    grow:false,
    // Edit: () => null,
    enableEditing:false
  },
  {
    accessorKey: "ordd_cantidad",
    accessorFn: (row) => `${parseFloat(row.ordd_cantidad).toFixed(2)}`,
    header: "CANTIDAD",
    size: 30,
    grow:false,
    minSize:30,
  },
  {
    accessorFn: (row) => `$${roundNumber(Number(row.ordd_total),3).toFixed(3)}`,
    accessorKey: "ordd_total",
    header: "TOTAL",
    size: 40,
    minSize:40,
    maxSize:40,
    grow:false,
    Edit: () => null,
    enableEditing: false
  }
]};
export function COLUMNS_FAC_FACTURA_DETALLE(usr_precio){ 
  return [
  {
    accessorKey: "art_upc",
    header: "CÓDIGO",
    size: 40,
    minSize:40,
    maxSize:40,
    grow:false,
    enableEditing: false
  },
  {
    accessorKey: "art_descripcion",
    header: "DESCRIPCIÓN",
    grow:false,
    // Edit: () => null,
    enableEditing: usr_precio==="1"?true:false,
    
  },
  {
    accessorKey: "fcd_precio",
    header: "PRECIO",
    accessorFn: (row) => `$${parseFloat(row.fcd_precio).toFixed(2)}`,
    size: 40,
    minSize:40,
    grow:false,
    // Edit: () => null,
    enableEditing: usr_precio==="1"?true:false
  },
  {
    accessorKey: "fcd_cantidad",
    accessorFn: (row) => `${parseFloat(row.fcd_cantidad).toFixed(2)}`,
    header: "CANTIDAD",
    size: 30,
    grow:false,
    minSize:30,
  },
  {
    // accessorFn: (row) => `${roundNumber(Number(row.fcd_itbms),3).toFixed(3)}`,
    accessorKey: "fcd_itbms",
    header: "ITBMS",
    size: 20,
    minSize:20,
    id:"fcd_itbms",
    grow:false,
    Edit: () => null,
    enableEditing: false,
    Cell: ({ cell }) => 
      {
        return <>
        
        <span>${roundNumber(Number(cell.row.original.fcd_itbms),3).toFixed(3)}</span>
        {
          cell.row.original.art_itbm_venta==="0.00"?<span className="porcent-label-exento">{"Ex"}</span>:<span className="porcent-label">{(Number(cell.row.original.art_itbm_venta)*100).toFixed(0)+"%"}</span>
        }
        
        </>
        
      }
  },
  {
    accessorFn: (row) => `$${roundNumber(Number(row.fcd_total),3).toFixed(3)}`,
    accessorKey: "fcd_total",
    header: "TOTAL",
    size: 40,
    minSize:40,
    maxSize:40,
    grow:false,
    Edit: () => null,
    enableEditing: false
  }
]};

export function COLUMNS_COTD_COOTIZACION_DETALLE(usr_precio){ 
  return [
  {
    accessorKey: "art_upc",
    header: "CÓDIGO",
    size: 250,
    minSize:200,
    enableEditing: false,
  },
  {
    accessorKey: "art_descripcion",
    header: "DESCRIPCIÓSSN",
    size: 250,
    minSize:200,
    enableEditing: usr_precio==="1"?true:false,
  },
  {
    accessorFn: (row) => `${parseFloat(row.cotd_precio).toFixed(2)}`,
    accessorKey: "cotd_precio",
    header: "PRECIO",
    size: 40,
    minSize:40,
    enableEditing: usr_precio==="1"?true:false
  },

  {
    accessorFn: (row) => `${parseFloat(row.cotd_cantidad).toFixed(2)}`,
    accessorKey: "cotd_cantidad",
    header: "CANTIDAD",
    size: 40,
    minSize:40,
  },
  {
    accessorFn: (row) => `${row.art_itbm_cod_v==="00"?"0.000":roundNumber(Number(row.cotd_itbms),3).toFixed(3)}`,
    accessorKey: "cotd_itbms",
    header: "ITBMS",
    size: 40,
    minSize:40,
    enableEditing: false,
    Edit: () => null,
  },
  {
    accessorFn: (row) => `${roundNumber(Number(row.cotd_total),3).toFixed(3)}`,
    accessorKey: "cotd_total",
    header: "TOTAL",
    size: 60,
    minSize:40,
    enableEditing: false,
    Edit: () => null,
  }
]};

export function COLUMNS_LISTA_CREDITOS(){ 
  return [
    {
      accessorKey: "cli_nombre",
      accessorFn: (row) => `${row.cli_ruc !==""? row.cli_nombre+ " "  + row.cli_ruc: row.cli_nombre}`,
      header: "NOMBRE",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "cli_ruc", 
      accessorFn: (row) => `${row.cli_dv !==""? row.cli_ruc+ " DV "  + row.cli_dv: row.cli_ruc}`,
      header: "RUC",
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "crd_monto_c", 
      accessorFn: (row) => `${(Number(row.crd_monto_c)).toFixed(2)}`,
      header: "TOTAL",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "crp_monto_p", 
      accessorFn: (row) => `${(Number(row.crd_monto_c)-Number(row.crp_monto_p)).toFixed(2)}`,
      header: "SALDO ADEUDADO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
]};

export function COLUMNS_CREDITOS_PAGO(){ 
  return [
    {
      accessorKey: "fac_id", 
      header: "N° FACTURA",
      accessorFn: (row) => `${row.fac_id.toString().padStart(10, "0")}`,
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "cli_nombre", 
      header: "CLIENTE",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "cli_ruc", 
      header: "RUC",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "crp_monto", 
      header: "MONTO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorFn: (row) => `${formatAMPM(new Date(row.crp_fecha ))}`,
      accessorKey: "crp_fecha", 
      header: "FECHA",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorFn: (row) => `${formatAMPM(new Date(row.crp_fecha_delete ))}`,
      accessorKey: "crp_fecha_delete", 
      header: "ELIMINADO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
]};

export function COLUMNS_CREDITOS(){ 
  return [
    {
      accessorKey: "fac_id", 
      header: "N° FACTURA",
      accessorFn: (row) => `${row.fac_id.toString().padStart(10, "0")}`,
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "cli_nombre", 
      header: "CLIENTE",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "cli_ruc", 
      header: "RUC",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "crd_monto", 
      header: "MONTO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorFn: (row) => `${formatAMPM(new Date(row.crd_fecha ))}`,
      accessorKey: "crd_fecha", 
      header: "FECHA",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
]};

export function COLUMNS_LISTA_CREDITOS_PAGO(){ 
  return [
    {
      accessorKey: "crp_id", 
      header: "N° RECIBO",
      accessorFn: (row) => `${row.crp_id.toString().padStart(10, "0")}`,
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "crp_monto", 
      header: "MONTO ABONADO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorFn: (row) => `${formatAMPM(new Date(row.crp_fecha ))}`,
      accessorKey: "crp_fecha", 
      header: "FECHA",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
]};

export function COLUMNS_LISTA_CREDITOS_DETALLE(printFac){ 
  return [
    {
      accessorKey: "fac_id",
      // accessorFn: (row) => `${row.fac_id.toString().padStart(10, "0")}`,
      header: "N° FACTURA",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ row,cell }) => {
        return <Box className="d-flex">
          <MDBIcon fas icon="print" className="me-3 class-btn-factura-datatable" onClick={() =>printFac({data:row.original,tipo:row.original.fac_recibo} )}/>
          {/* <MDBIcon fas icon="copy" className="me-3 class-btn-factura-datatable" onClick={() =>printFac({data:row.original,tipo:"0"} )}/> */}
          <Label className="class-btn-factura">
              {row.original.fac_id.toString().padStart(10, "0")}
            </Label>
    </Box>
      },
    },
    {
      accessorKey: "fac_fecha", 
      accessorFn: (row) => `${formatAMPM(new Date(row.fac_fecha ))}`,
      header: "FECHA FACTURA",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "crd_monto_c", 
      header: "TOTAL",
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "crp_monto_p", 
      header: "MONTO PAGADO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorFn: (row) => `${(Number(row.crd_monto_c)-Number(row.crp_monto_p)).toFixed(2)}`,
      accessorKey: "crd_monto_s", 
      header: "SALDO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    
]};
export function COLUMNS_LISTA_ORDENES(){ 
  return [
    {
      accessorKey: "usr_nombre",
      header: "MESERO",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "ord_identificador", 
      header: "# ORDEN",
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "ord_mesa", 
      header: "# MESA",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "ord_fecha", 
      header: "FECHA",
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "ordd_total", 
      accessorFn: (row) => `${parseFloat(row.ordd_total).toFixed(2)}`,
      header: "TOTAL",
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
]};
export function COLUMNS_LISTA_COTIZACION(){ 
  return [
    {
      accessorKey: "cli_nombre",
      accessorFn: (row) => `${row.cli_ruc !==""? row.cli_nombre+ " "  + row.cli_ruc: row.cli_nombre}`,
      header: "NOMBRE",
      enableGlobalFilter: true, 
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: "cot_id", 
      header: "# COTIZACIÓN",
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "cot_descripcion", 
      header: "DESCRIPCIÓN",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: "cot_fecha", 
      header: "FECHA",
      size: 80,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
]};

export function COLUMNS_LISTA_CLIENTE(){ 
  return [
    {
      accessorKey: "cli_nombre",
      header: "NOMBRE",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableEditing: true,
    },
    {
      accessorKey: "cli_ruc", 
      header: "RUC",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableEditing: true,
    },
    {
      accessorKey: "cli_dv", 
      header: "RUC",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableEditing: true,
    },
    {
      accessorKey: "cli_telefono", 
      header: "TELÉFONO",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableEditing: true,
    },
    {
      accessorKey: "cli_email", 
      header: "E-MAIL",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      enableEditing: true,
    },
]};

export const  COLUMNS_MOV_MOVIMIENTO = [
  {
    accessorKey: "art_upc",
    header: "CÓDIGO",
  },
  {
    accessorKey: "art_descripcion", //normal accessorKey
    header: "DESCRIPCIÓN",
  },
  {
    accessorKey: "art_categoria", //normal accessorKey
    header: "CATEGORIA",
  },
  {
    accessorKey: "art_marca", //normal accessorKey
    header: "MARCA",
  },
  {
    accessorKey: "art_linea_roja", //normal accessorKey
    header: "LÍNEA ROJA",
  },
  {
    accessorKey: "inv_qty", //normal accessorKey
    header: "EXISTENCIA",
  }
];

export const  COLUMNS_RE_CUENTO = [
    {
      accessorKey: "re_id",
      header: "# INVENTARIO",
    },
    {
      accessorKey: "re_descripcion", //normal accessorKey
      header: "DESCRIPCIÓN",
      size: 100,
    },
    {
      accessorKey: "re_fecha", //normal accessorKey
      header: "FECHA",
      size: 100,
    },
    {
      accessorFn: (row) => `${row.re_fecha_procesado !=="0000-00-00 00:00:00"?formatAMPM(new Date(row.re_fecha_procesado )):""}`,
      accessorKey: "re_fecha_procesado", //normal accessorKey
      header: "FECHA PROCESADO",
      size: 100,
    }
];

export function COLUMNS_RE_RECUENTO_DETALLE(deleteRow,onBlurs){ 
  return [
  {
    accessorKey: "art_upc", //access nested data with dot notation
    header: "CÓDIGO",
    size: 120,
    minSize:100,
    enableEditing: false
  },
  {
    accessorKey: "art_descripcion",
    header: "DESCRIPCIÓN",
    size: 250,
    minSize:200,
    enableEditing: false
  },
  {
    accessorKey: "red_cantidad",
    header: "CANTIDAD",
    size: 40,
    minSize:40,
  },
]};