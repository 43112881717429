import React, { useState, useEffect, useRef } from "react";
import { Container, Label, Row, Col, NavLink } from "reactstrap";
import moment from "moment";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { NavLink as RRNavLink, Outlet } from "react-router-dom";
import {LineChart} from 'amazing-react-charts'
import { CLOSE_IMAGE } from "../Componentes/Image";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { ComponentToPrintsReporteVentas, ComponentToPrintsReporteCreditos,ComponentToPrintsReporteIC,ComponentToPrintsReporteVM,ComponentToPrintsReportePVM,ComponentToPrintsReporteUM } from "../Inventario/ComponentToPrintss";
import ReactToPrint from "react-to-print-advanced";
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import BarChartIcon from '@mui/icons-material/BarChart';
import { IconButton,Button } from "@mui/material";
import { DateRangeModal } from "../Componentes/Modal";
import { MDBIcon, MDBTabs, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";
import {EXPORT_CSV_CREDITOS_PAGO,EXPORT_CSV_CREDITOS} from "./../Componentes/Util";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "./../Componentes/www/style.css";
import {DATATABLE_CREDITOS_PAGO,DATATABLE_CREDITOS} from "./../Componentes/Datatables";
import { get_report_creditos,get_report_venta, get_report_x_global,get_report_ventas_mensuales,get_report_productos_masvendidos,get_report_utilidad_margen,  get_compania } from "./../Componentes/Services";
import{monthname} from "../Componentes/Services";

export const Reporte = () => {
  var subtotal = 0;
  var itbms = 0;
  var total = 0;
  var creditos =0;
  var exento = 0;
  var objeDataTotales = [];
  var componentRef = useRef();
  const [vendedorChecked, setCheckvendedor] = useState(false);
  const [tabsActive, setTabsActive] = useState("tab1");
  const [isPrinting, setPrintI] = useState(false);
  const [isDateRangeOpen, setDateRange] = useState(false);
  const [isPrintingC, setPrintIC] = useState(false);
  const [isPrintingRC, setPrintRC] = useState(false);
  const [isPrintingVM, setPrintVM] = useState(false);
  const [isPrintingPVM, setPrintPMV] = useState(false);
  const [isPrintingUM, setPrintUM] = useState(false);
  const [dataReporteInformeCaja, setReportInformeCaja] = useState([]);
  const [flagReporteCreditos, setOpenReporteCreditos] = useState(false);
  const [flagInfomeCaja, setOpenFlagInformeCaja] = useState(false);
  const [flagProductosMasVendidos, setFlagProductosMasVEndidos] = useState(false);
  const [flagUtilidadMargen, setFlagUtilidadMargen] = useState(false);
  const [flagGraficaVentasMensuales, setFlagVentasMensuales] = useState(false);
  const [dataReporteCreditos, setReportCreditos] = useState([]);
  const [dataReportePagoCreditos, setReportPagoCreditos] = useState([]);
  const [dataReporteVentasExento, setReportVentaExento] = useState([]);
  const [dataReporteVentas, setReportVenta] = useState([]);
  const [dataReporteVentasFpago, setReportVentaFpago] = useState([]);
  const [dataReporteVentasMontosT, setReportVentaMontosT] = useState([]);
  const [dataReporteVentasCreditos, setReportVentaCreditos] = useState([]);
  const [dataGroupVendedor, setGroupVendedor] = useState([]);
  const [dataReportVentasMensuales, setReportVentasMensuales] = useState([]);
  const [dataReportUtilidadMargen, setReportUtilidadMargen] = useState([]);
  const [dataReporProductosVendidos, setReportProductosMasVendidos] = useState([]);
  const [dataCompania, setDataCompania] = useState({
    bod_suc: "",
    com_nombre: "",
    com_ruc: "",
    com_dv: "",
    com_direccion: "",
    com_telefono: "",
    com_telefono2: "",
    com_email: "",
    com_fax: "",
    com_web: "",
    com_info_interes:"",
    com_cotizacion_info:""
  });
  const [open, setOpen] = useState(false);
  const [flag, setOpenFlag] = useState(0);
  const [state, setState] = useState([{
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    key: "selection"
  }]);
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: "reporte_de_ventas"+moment(new Date()).format("_YYYY_MM_DD")+".csv",
  });
  async function fetchCompania() {
    var response;
    response = await get_compania();
    var data = response[0];
    setDataCompania({
      bod_suc: data.bod_suc,
      com_nombre: data.com_nombre,
      com_ruc: data.com_ruc,
      com_dv: data.com_dv,
      com_direccion: data.com_direccion,
      com_telefono: data.com_telefono,
      com_telefono2: data.com_telefono2,
      com_email: data.com_email,
      com_fax: data.com_fax,
      com_web: data.com_web,
      com_info_interes:data.com_info_interes,
      com_cotizacion_info:data.com_cotizacion_info
    });
  }
  
  const handleExportCsvCredito = () => {
    EXPORT_CSV_CREDITOS({dataCreditos:dataReporteCreditos})
  };

  const handleExportCsvPago = () => {
    EXPORT_CSV_CREDITOS_PAGO({dataCreditospago:dataReportePagoCreditos})
  };
  
  useEffect(() => {
    fetchCompania();
  }, []);
  const handleExportData = () => {
    var report_v= dataReporteVentas.map((data,i)=>{
      return{
        ID:data.fac_id,
        Fecha:data.fac_fecha,
        Cliente:data.cli_nombre,
        Vendedor:data.usr_nombre,
        Caja:data.fac_caja,
        Fecha_anulacion:data.fac_date_anulacion,
        Anulacion:data.fac_anulacion !== "1" ? "" : "ANULADO",
        Exento:data.fcd_itbms_code==="00"?"Exento":"",
        Subtotal:data.fcd_precio,
        Itbms:data.fcd_itbms,
        Total:data.fcd_total
      }
    })
    const csv = generateCsv(csvConfig)(report_v);
    download(csvConfig)(csv);
  };


  dataReporteVentasMontosT.map((data) => {
    // if (data.fac_anulacion !== "1") {
      itbms = Number(data.fcd_itbms)
      total =  Number(data.fcd_total)
      subtotal = data.fcd_precio
    // }
  })

  if(dataReporteVentasExento[0]!==undefined){
    exento=Number(dataReporteVentasExento[0].fcd_total);
  }
  if(dataReporteVentasCreditos[0]!==undefined){
    creditos=Number(dataReporteVentasCreditos[0].crd_monto);
  }

  objeDataTotales = {
    itbms,
    subtotal,
    total,
    exento,
    startDate: moment(state[0].startDate).format("YYYY-MM-DD"),
    Endate: moment(state[0].endDate).format("YYYY-MM-DD"),
  }

  const handleTabClick = (value) => {
    if (value === tabsActive) {
      return;
    }
    setTabsActive(value);
    setOpenFlagInformeCaja(false)
    setReportVenta([])
    setReportInformeCaja([])
    setPrintI(false)
    setPrintIC(false)
  };
  
  async function fetchProductosMasvendidos({ startDate, endDate }) {
    var response = await get_report_productos_masvendidos({startDate, endDate});
    response = response.map((data,i)=>{
      return{...data,
        label: data.art_descripcion,
        result: data.cantidad,
      }
    })
    setReportProductosMasVendidos(response);
  }

  async function fetchUtilidadMargen({ startDate, endDate }) {
    var response = await get_report_utilidad_margen({startDate, endDate});
    // response = response.map((data,i)=>{
    //   return{
    //     label: monthname[data.fac_fecha].name,
    //     result: data.fac_total,
    //     itbms: data.fac_itbms,
    //     itemId: 1,
    //   }
    // })
    setReportUtilidadMargen(response);
  }

  async function fetchVentasMensuales({ startDate, endDate }) {
    var response = await get_report_ventas_mensuales({startDate, endDate});
    response = response.ventas_mensuales.map((data,i)=>{
      return{
        label: monthname[data.fac_fecha].name,
        result: data.fac_total,
        itbms: data.fac_itbms,
        itemId: 1,
      }
    })
    setReportVentasMensuales(response);
  }
  
  async function fetchReporteCreditos({ startDate, endDate }) {
    var response = await get_report_creditos({startDate, endDate});
    setReportCreditos(response.reporte_creditos);
    setReportPagoCreditos(response.reporte_pagoCredito);
    setOpenReporteCreditos(true);
  }

  async function fetchReporte({ startDate, endDate }) {
    var response = await get_report_venta(startDate, endDate);
    setReportVenta(response.ventas_global);
    setReportVentaFpago(response.fPago);
    setReportVentaExento(response.exento);
    setReportVentaMontosT(response.montos_totales);
    setReportVentaCreditos(response.creditos)
    
    var report = [];
    var hashReport = {};
    report = response.ventas_global.filter((o) => (hashReport[o.usr_id] || o.usr_id === null ? false : (hashReport[o.usr_id] = true)));
    setGroupVendedor(report)
  }

  async function fetchReporteInformeCaja({ startDate, endDate }) {
    var response = await get_report_x_global(startDate, endDate);
    setReportInformeCaja(response);
    response.montos_totales[0] !== undefined ? setOpenFlagInformeCaja(true) : setOpenFlagInformeCaja(false);
  }

  const handleOnChangeDate = (ranges) => {
    const { selection } = ranges;
    setOpenFlag(Number(flag) + 1)
    setState([selection]);
    if (flag === 1) {
      if(tabsActive === "tab1"){
        fetchReporte({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD"), group_vendedor: vendedorChecked })
      }
      if(tabsActive === "tab2") {
        fetchReporteInformeCaja({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD") })
      }
      if(tabsActive === "tab3") {
        fetchReporteCreditos({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD") })
      }
      if(tabsActive === "tab4") {
        fetchVentasMensuales({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD") })
      }
      if(tabsActive === "tab5") {
        fetchProductosMasvendidos({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD") })
      }
      if(tabsActive === "tab6") {
        fetchUtilidadMargen({ startDate: moment(selection.startDate).format("YYYY-MM-DD"), endDate: moment(selection.endDate).format("YYYY-MM-DD") })
      }
      setOpen(false);
      setOpenFlag(0)
      setDateRange(false)
      setOpenFlagInformeCaja(false);
      setFlagProductosMasVEndidos(false);
      setOpenReporteCreditos(false)
    }
  };

  return (
    <Container fluid>

      {isDateRangeOpen && (
        <DateRangeModal
          isOpen={isDateRangeOpen}
          toggle={setDateRange}
          // setOpen={setOpen}
          open={open}
          handleOnChangeDate={handleOnChangeDate}
          state={state}
        />
      )}
      <Row className="background-divs">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">REPORTES</Label>
        </Col>
      </Row>
      <>
        <MDBTabs className="d-flex-none custom-navs">
          <Row>
            <Col xs={2} >
              <MDBTabsLink onClick={() => handleTabClick("tab1")} active={tabsActive === "tab1"} className="padding-2porc">
                <MDBIcon fas icon="money-bill" className="me-2" /> Reporte de ventas
              </MDBTabsLink>
            </Col>
            <Col xs={2}>
              <MDBTabsLink onClick={() => handleTabClick("tab2")} active={tabsActive === "tab2"} className="padding-2porc">
                <MDBIcon fas icon="cash-register" className="me-2" /> Informes de caja
              </MDBTabsLink>
            </Col>
            <Col xs={2}>
            <MDBTabsLink onClick={() => handleTabClick("tab3")} active={tabsActive === "tab3"} className="padding-2porc">
                <MDBIcon fas icon="cash-register" className="me-2" /> Reporte de créditos
              </MDBTabsLink>
              </Col>
            <Col xs={2}>
              <MDBTabsLink onClick={() => handleTabClick("tab4")} active={tabsActive === "tab4"} className="padding-2porc">
                <MDBIcon fas icon="cash-register" className="me-2" /> Ventas mensuales
              </MDBTabsLink>
            </Col>
            <Col xs={2}>
              <MDBTabsLink onClick={() => handleTabClick("tab5")} active={tabsActive === "tab5"} className="padding-2porc">
                <MDBIcon fas icon="cash-register" className="me-2" /> Productos más vendidos
              </MDBTabsLink>
            </Col>
            <Col xs={2} className="d-flex">
            <MDBTabsLink onClick={() => handleTabClick("tab6")} active={tabsActive === "tab6"} className="padding-2porc">
                <MDBIcon fas icon="cash-register" className="me-2" /> Utilidad
              </MDBTabsLink>
              <NavLink tag={RRNavLink} to="/user/home" className="font-w-bold   menu-l-c">
                        <img src={CLOSE_IMAGE} className="src-image" alt="..."/>
                      </NavLink>
              </Col>
          </Row>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane show={tabsActive === "tab1"}>
            {
              isPrinting ?
                <>
                  <Row>
                    <Col xs={10}></Col>
                    <Col xs={2} >
                      <IconButton className="float-right-btn"
                        onClick={() => {
                          setPrintI(false);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <ReactToPrint
                        trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                        content={() => componentRef} />
                    </Col>
                    <Col xs={12} className="text-center scrol-table2">
                      <ComponentToPrintsReporteVentas
                        ref={el => (componentRef = el)}
                        props={dataReporteVentas}
                        dataReporteVentasFpago={dataReporteVentasFpago}
                        objeData={objeDataTotales}
                        formCompania={dataCompania}
                      />
                    </Col>
                  </Row>
                </> :
                <>
                  <Row className="mt-3">
                    <Col xs={12} className="text-center mt-2">
                      <Label className="font-weight-bold-title">REPORTE DE VENTAS</Label>
                    </Col>

                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        label="SUBTOTAL"
                        autoFocus={true}
                        value={dataReporteVentas.length > 0 ? Number(Number(subtotal)).toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        wrapperClass="mb-4"
                        label="ITBMS"
                        value={dataReporteVentas.length > 0 ? itbms.toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        id="fcd_i_total_e"
                        label="EXENTO"
                        value={dataReporteVentas.length > 0 ? exento.toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        id="fcd_i_creditos"
                        label="CRÉDITOS"
                        value={dataReporteVentas.length > 0 ? creditos.toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>
                    <Col xs={2}>
                      <MDBInput
                        className="input-iventario-totales"
                        id="fcd_i_total"
                        label="TOTAL"
                        value={dataReporteVentas.length > 0 ? total.toFixed(2) : null}
                        disabled={true}
                      />
                    </Col>

                    <Col xs={2} className="d-flex">
                    <Row>
  <Col xs={12} className="text-center">
  {dataReporteVentas[0] === undefined ? null :
                       <>
                       <IconButton
                        className="position-icon" 
                          onClick={() => {
                            setPrintI(true);
                          }}
                        >
                          <LocalPrintshopIcon />
                        </IconButton>
                       
                       <Button className="position-icon" 
                  //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                  onClick={handleExportData}
                  startIcon={<FileDownloadIcon />}
                >
                  CSV
                </Button></> 

                      }
                      <IconButton className="position-icon" onClick={() => {
                        setOpen(true); setDateRange(true); setState([{
                          startDate: moment(new Date()).format("YYYY-MM-DD"),
                          endDate: moment(new Date()).format("YYYY-MM-DD"),
                          key: "selection"
                        }])
                      }}>

                        <CalendarMonthIcon />
                      </IconButton>
  </Col>
  <Col xs={12} className="text-center">
  {state[0].startDate !== "" && state[0].endDate !== "" && (
                        <p className="date-p-label">{" "}
                          {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                          {moment(state[0].endDate).format("YYYY-MM-DD")}
                        </p>
                      )}
  </Col>
</Row>

                    </Col>


                    <Col xs={12}>
                      {open ? null :
                        <>
                          {dataReporteVentas[0] !== undefined ? <>

                            <Row>
                              <Col xs={12} className="scrol-table">
                                <MDBTable striped className="custom-table">
                                  <MDBTableHead dark className="table-dark-custom">
                                    <tr>
                                      <th className="text-center">N° documento</th>
                                      <th className="text-center">Fecha</th>
                                      <th className="text-center">Cliente</th>
                                      <th className="text-center">Vendedor</th>
                                      <th className="text-center">Caja</th>
                                      <th className="text-center">Fecha Anulado</th>
                                      <th className="text-center">Anulacion</th>
                                       <th className="text-center w-500px">EXENTO</th>
                                      <th className="text-center">subtotal</th>
                                      <th className="text-center">ITBMS</th>
                                      <th className="text-center">F. Pago</th>
                                      <th className="text-centerx">Total</th>
                                    </tr>
                                  </MDBTableHead>
                                  <MDBTableBody>

                                    {
                                      dataReporteVentas.map((data, i) => {
                                        return <tr key={i}>
                                          <td className="text-center">
                                            <Label>{data.fac_id.toString().padStart(10, "0")}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_fecha}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.cli_nombre}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.usr_nombre}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_caja}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_date_anulacion}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fac_anulacion !== "1" ? "" : "ANULADO"}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fcd_itbms_code==="00"?"Exento":null}</Label>
                                          </td>

                                          <td className="text-center">
                                            <Label>{data.fcd_precio}</Label>
                                          </td>
                                          <td className="text-center">
                                            <Label>{Number(data.fcd_itbms).toFixed(2)}</Label>
                                          </td>
                                          <td className="text-center">
                                            {
                                              dataReporteVentasFpago.map((datap, i) => {
                                                if (data.fac_id === datap.fac_id) {
                                                  return <><Label className="label-fpago">&nbsp;&nbsp;&nbsp;{datap.fcfp_fpago}{": "}{datap.fcfp_monto}</Label></>
                                                }
                                              })
                                            }
                                          </td>
                                          <td className="text-center">
                                            <Label>{data.fcd_total}</Label>
                                          </td>
                                        </tr>
                                      })
                                    }
                                  </MDBTableBody>
                                </MDBTable>

                              </Col>
                            </Row>
                          </>
                            : null}
                        </>
                      }
                    </Col>
                  </Row>
                </>
            }
          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab2"}>
            {isPrintingC ?
              <>
                <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintIC(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                    <ComponentToPrintsReporteIC
                      ref={el => (componentRef = el)}
                      dataReporteInformeCaja={dataReporteInformeCaja}
                      formCompania={dataCompania}
                    />
                  </Col>
                </Row>
              </> :
              <>
                <Row>
                  <Col xs={12} className="text-center mt-3">
                    <Label className="font-weight-bold-title">REPORTE DE INFORMES DE CAJA</Label>
                  </Col>
                  <Col xs={9} className="text-end">
                    {flagInfomeCaja === false ? null : <IconButton
                      onClick={() => {
                        setPrintIC(true);
                      }}
                    >
                      <LocalPrintshopIcon />
                    </IconButton>
                    }
                    <IconButton onClick={() => {
                      setOpen(true); setDateRange(true); setState([{
                        startDate: moment(new Date()).format("YYYY-MM-DD"),
                        endDate: moment(new Date()).format("YYYY-MM-DD"),
                        key: "selection"
                      }])
                    }}>
                      <CalendarMonthIcon />
                    </IconButton>

                  </Col>
                  <Col xs={3} className="text-right">
                    {state[0].startDate !== "" && state[0].endDate !== "" && (
                      <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(state[0].endDate).format("YYYY-MM-DD")}
                      </p>
                    )}
                  </Col>
                  <Col>
                    {flagInfomeCaja ?
                      <>
                        <Row className="scrol-table3">
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Ventas registradas</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Forma de pago</th>
                                  <th>Monto</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.montos_fpago.map((data, i) => {
                                    return <tr>
                                      <th>{data.fcfp_fpago}</th>
                                      <td>{data.fcfp_monto}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>

                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Total de exentos de ITBMS</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Total</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.exentos.map((data, i) => {
                                    return <tr>
                                      <td>{data.fcd_total}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Anulaciones registradas</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Vendedor</th>
                                  <th>Numero de documento</th>
                                  <th>Emitido</th>
                                  <th>Anulado</th>
                                  <th>Total</th>
                                  <th>Motivo</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.anulacion.map((data, i) => {
                                    return <tr>
                                      <th>{data.usr_nombre}</th>
                                      <td>{data.fac_id}</td>
                                      <th>{data.fac_fecha}</th>
                                      <td>{data.fac_date_anulacion}</td>
                                      <th>{data.fcd_total}</th>
                                      <td>{data.fac_motivo_anulacion}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Totales</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Ventas subtotal</th>
                                  <th>Ventas con exento</th>
                                  <th>ITBMS</th>
                                  <th>Total</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.montos_totales.map((data, i) => {
                                    return <tr>
                                      <th>{Number(data.fcd_precio)+Number(dataReporteInformeCaja.exentos[0].fcd_total)}</th>
                                      <th>{Number(dataReporteInformeCaja.exentos[0].fcd_total).toFixed(2)}</th>
                                      <td>{Number(data.fcd_itbms).toFixed(2)}</td>
                                      <th>{data.fcd_total}</th>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                        </Row>

                      </> : null
                    }
                  </Col>
                </Row>
              </>
            }
          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab3"}>
            {isPrintingRC ?
              <>
                <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintRC(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                    <ComponentToPrintsReporteCreditos
                      ref={el => (componentRef = el)}
                      dataReporteCreditos={dataReporteCreditos}
                      dataReporteCreditosPago={dataReportePagoCreditos}
                      formCompania={dataCompania}
                    />
                  </Col>
                </Row>
              </> :
              <>
                <Row>
                  <Col xs={12} className="text-center mt-3">
                    <Label className="font-weight-bold-title">REPORTE DE CRÉDITOS</Label>
                  </Col>
                  <Col xs={9} className="text-end">
                    {flagReporteCreditos === false ? null : <IconButton
                      onClick={() => {
                        setPrintRC(true);
                      }}
                    >
                      <LocalPrintshopIcon />
                    </IconButton>
                    }
                    <IconButton onClick={() => {
                      setOpen(true); setDateRange(true); setState([{
                        startDate: moment(new Date()).format("YYYY-MM-DD"),
                        endDate: moment(new Date()).format("YYYY-MM-DD"),
                        key: "selection"
                      }])
                    }}>
                      <CalendarMonthIcon />
                    </IconButton>

                  </Col>
                  <Col xs={3} className="text-right">
                    {state[0].startDate !== "" && state[0].endDate !== "" && (
                      <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(state[0].endDate).format("YYYY-MM-DD")}
                      </p>
                    )}
                  </Col>
                  <Col>
                    {flagReporteCreditos ?
                      <>
                        <Row className="scrol-table3">
                          <Col xs={12} className="text-center modal-add-inventario datatable-factura">
                            <Label className="report-title">Créditos registrados</Label>
                            <DATATABLE_CREDITOS dataCreditos={dataReporteCreditos} handleExportData={handleExportCsvCredito}/>
                            {/* <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th># FACTURA</th>
                                  <th>CLIENTE</th>
                                  <th>RUC</th>
                                  <th>MONTO</th>
                                  <th>FECHA</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteCreditos.map((data, i) => {
                                    return <tr>
                                      <th>{data.fac_id.toString().padStart(10, "0")}</th>
                                      <td>{data.cli_nombre}</td>
                                      <th>{data.cli_ruc}</th>
                                      <td>{data.crd_monto}</td>
                                      <th>{data.crd_fecha}</th>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable> */}

                          </Col>
                          <Col xs={12} className="text-center modal-add-inventario datatable-factura">
                            <Label className="report-title">Abonos a créditos</Label>
                            <DATATABLE_CREDITOS_PAGO dataCreditospago={dataReportePagoCreditos} handleExportData={handleExportCsvPago}/>
                            {/* <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th># FACTURA</th>
                                  <th>CLIENTE</th>
                                  <th>RUC</th>
                                  <th>MONTO</th>
                                  <th>FECHA PAGO</th>
                                  <th>ELIMINADO</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReportePagoCreditos.map((data, i) => {
                                    return <tr>
                                      <td>{data.fac_id.toString().padStart(10, "0")}</td>
                                      <td>{data.cli_nombre}</td>
                                      <td>{data.cli_ruc}</td>
                                      <td>{data.crp_monto}</td>
                                      <td>{data.crp_fecha}</td>
                                      <td>{data.crp_fecha_delete}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable> */}
                          </Col>
                        </Row>

                      </> : null
                    }
                  </Col>
                </Row>
              </>
            }
          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab4"}>
            {isPrintingVM ?
              <>
                <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintVM(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                    <ComponentToPrintsReporteVM
                      ref={el => (componentRef = el)}
                      dataReportVentasMensuales={dataReportVentasMensuales}
                      endDate ={state[0].endDate}
                      startDate={state[0].startDate}
                      formCompania={dataCompania}
                    />
                  </Col>
                </Row>
              </>:          <Row>
            <Col xs={12} className="text-center mt-3">
              <Label className="font-weight-bold-title">REPORTE VENTAS MENSUALES</Label>
            </Col>
            <Col xs={9} className="text-end">
              <IconButton onClick={() => {
                setOpen(true); setDateRange(true); setFlagVentasMensuales(false);setState([{
                  startDate: moment(new Date()).format("YYYY-MM-DD"),
                  endDate: moment(new Date()).format("YYYY-MM-DD"),
                  key: "selection"
                }])
              }}>
                <CalendarMonthIcon />
              </IconButton>
              {flagGraficaVentasMensuales?<IconButton onClick={() => {setFlagVentasMensuales(false)}}>
                <ClearIcon />
              </IconButton>:<>
              {dataReportVentasMensuales[0] === undefined ? null :<><IconButton onClick={() => {setFlagVentasMensuales(true)}}>
                  <BarChartIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setPrintVM(true);
                  }}>
                  <LocalPrintshopIcon />
                </IconButton></>}
                
              </>
              }
            </Col>
            <Col xs={3} className="text-right">
                    {state[0].startDate !== "" && state[0].endDate !== "" && (
                      <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(state[0].endDate).format("YYYY-MM-DD")}
                      </p>
                    )}
                  </Col>
            <Col xs={12} className="text-center">
            {flagGraficaVentasMensuales?
              <LineChart
              showBarLabel
              yComplement="$"
              xType="category"
              yType="value"
              colors={['red', 'green', 'blue']}
              title="REPORTE VENTAS MENSUALES"
              toolboxTooltip={{ saveAsImage: 'save' }}
              // tooltip={{ label: 'Equipamento', result: 'Disponibilidade' }}
              data={[{name:"Venta",values:dataReportVentasMensuales}]}
            />:
            dataReportVentasMensuales[0] !==undefined? <MDBTable striped className="custom-table">
              <MDBTableHead dark>
                <tr>
                  <th>Mes</th>
                  <th>ITBMS</th>
                  <th>Monto</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {
                  dataReportVentasMensuales.map((data, i) => {
                    return <tr>
                      <th>{data.label}</th>
                      <th>{data.itbms}</th>
                      <td>{data.result}</td>
                    </tr>
                  })
                }
              </MDBTableBody>
            </MDBTable>:null
            }

            </Col>
          </Row>}

          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab5"}>
            {isPrintingPVM ?
              <>
                <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintPMV(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                    <ComponentToPrintsReportePVM
                      ref={el => (componentRef = el)}
                      dataReporProductosVendidos={dataReporProductosVendidos}
                      endDate ={state[0].endDate}
                      startDate={state[0].startDate}
                      formCompania={dataCompania}
                    />
                  </Col>
                </Row>
              </>:          <Row>
            <Col xs={12} className="text-center mt-3">
              <Label className="font-weight-bold-title">PRODUCTOS MÁS VENDIDOS</Label>
            </Col>
            <Col xs={9} className="text-end">
              <IconButton onClick={() => {
                setOpen(true); setDateRange(true); setFlagProductosMasVEndidos(false);setState([{
                  startDate: moment(new Date()).format("YYYY-MM-DD"),
                  endDate: moment(new Date()).format("YYYY-MM-DD"),
                  key: "selection"
                }])
              }}>
                <CalendarMonthIcon />
              </IconButton>
              {flagProductosMasVendidos?<IconButton onClick={() => {setFlagProductosMasVEndidos(false)}}>
                <ClearIcon />
              </IconButton>:<>
               {dataReporProductosVendidos[0] !==undefined? <IconButton
                  onClick={() => {
                    setPrintPMV(true);
                  }}>
                  <LocalPrintshopIcon />
                </IconButton>:null}
              </>
              }
            </Col>
            <Col xs={3} className="text-right">
                    {state[0].startDate !== "" && state[0].endDate !== "" && (
                      <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(state[0].endDate).format("YYYY-MM-DD")}
                      </p>
                    )}
                  </Col>
            <Col xs={12} className="text-center">
    
            {dataReporProductosVendidos[0] !==undefined? <Row className="scrol-table3"><MDBTable striped className="custom-table">
            <MDBTableHead dark>
              <tr>
                <th>CÓDIGO</th>
                <th>Artículo</th>
                <th>Marca</th>
                <th>Categoría</th>
                <th>Cantidad</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {
                dataReporProductosVendidos.map((data, i) => {
                  return <tr>
                    <td>{data.art_upc}</td>
                    <td>{data.art_descripcion}</td>
                    <th>{data.mar_descripcion}</th>
                    <th>{data.cat_descripcion}</th>
                    <td>{data.cantidad}</td>
                  </tr>
                })
              }
            </MDBTableBody>
          </MDBTable></Row>:null
            }

            </Col>
          </Row>}

          </MDBTabsPane>
          <MDBTabsPane show={tabsActive === "tab6"}>
            {isPrintingUM ?
              <>
                <Row>
                  <Col xs={10}></Col>
                  <Col xs={2}>
                    <IconButton className="float-right-btn"
                      onClick={() => {
                        setPrintUM(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <ReactToPrint
                      trigger={() => <a href="#">  <IconButton className="float-right-btn"><LocalPrintshopIcon /></IconButton></a>}
                      content={() => componentRef} />
                  </Col>
                  <Col xs={12} className="text-center scrol-table2">
                    <ComponentToPrintsReporteUM
                      ref={el => (componentRef = el)}
                      dataReportUtilidadMargen={dataReportUtilidadMargen}
                      endDate ={state[0].endDate}
                      startDate={state[0].startDate}
                      formCompania={dataCompania}
                    />
                  </Col>
                </Row>
              </>:          <Row>
            <Col xs={12} className="text-center mt-3">
              <Label className="font-weight-bold-title">UTILIDAD Y MARGEN</Label>
            </Col>
            <Col xs={9} className="text-end">
              <IconButton onClick={() => {
                setOpen(true); setDateRange(true); setFlagUtilidadMargen(false);setState([{
                  startDate: moment(new Date()).format("YYYY-MM-DD"),
                  endDate: moment(new Date()).format("YYYY-MM-DD"),
                  key: "selection"
                }])
              }}>
                <CalendarMonthIcon />
              </IconButton>
              {flagUtilidadMargen?<IconButton onClick={() => {setFlagUtilidadMargen(false)}}>
                <ClearIcon />
              </IconButton>:<>
              {dataReportUtilidadMargen[0] !==undefined? <IconButton
                  onClick={() => {
                    setPrintUM(true);
                  }}>
                  <LocalPrintshopIcon />
                </IconButton>:null}
              </>
              }
            </Col>
            <Col xs={3} className="text-right">
                    {state[0].startDate !== "" && state[0].endDate !== "" && (
                      <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(state[0].startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(state[0].endDate).format("YYYY-MM-DD")}
                      </p>
                    )}
                  </Col>
            <Col xs={12} className="text-center">
    
            {dataReportUtilidadMargen[0] !==undefined? <Row className="scrol-table3"><MDBTable striped className="custom-table">
            <MDBTableHead dark>
              <tr>
                <th>CÓDIGO</th>
                <th>Artículo</th>
                <th>CANTIDAD</th>
                <th>COSTO</th>
                <th>TOTAL</th>
                <th>UTILIDAD</th>
                <th>MARGEN</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {
                dataReportUtilidadMargen.map((data, i) => {
                  return <tr className="height-40">
                    <td>{data.art_upc}</td>
                    <td>{data.art_descripcion}</td>
                    <th>{Number(data.fcd_cantidad).toFixed(2)}</th>
                    <th>{Number(data.art_costo).toFixed(2)}</th>
                    <td>{Number(data.art_total).toFixed(2)}</td>
                    <td>{(Number(data.art_total)-Number(data.art_costo)).toFixed(2)}</td>
                    <td>{((Number(data.art_total)-Number(data.art_costo))/Number(data.art_total)*100).toFixed(2)+"%"}</td>
                  </tr>
                })
              }
            </MDBTableBody>
          </MDBTable></Row>:null
            }

            </Col>
          </Row>}

          </MDBTabsPane>
        </MDBTabsContent>
      </>
      <Row className="background-divs fixed-div">
        <Col xs={12} className="text-center">
          <Label className="font-weight-bold-title">.</Label>
        </Col>

      </Row>
    </Container>
  )
}