import React from "react";
import moment from "moment";
import { Label, Row, Col } from "reactstrap";
import { LOGO } from "./../Componentes/Image";
import {roundNumber} from "./../Componentes/Util";
import { ReactBarcode } from 'react-jsbarcode';
import { imgURLocalImage } from "../Componentes/Services";
import {MDBIcon, MDBTabs,MDBTabsLink,MDBTabsContent,MDBTabsPane,MDBInput,MDBTextArea,MDBTable, MDBTableHead, MDBTableBody} from "mdb-react-ui-kit";
 class ComponentToPrintss extends React.Component {
    render() {
      var props = this.props.props;
      var formCompra = this.props.formCompra;
      var formCompania = this.props.formCompania;
      var subtotal = 0;
      var itbms = 0;
      var total = 0;
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report">
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
            <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
            <Col xs={12} className="text-start padding-print-body mt-2">
              <h5 className="text-center ">FACTURA DE COMPRA </h5>
              <Label className="fw-bold"># de documento : {formCompra.dc_docn}</Label>
              <br></br>
              <Label className="fw-bold">Proveedor : {formCompra.prov_proveedor}</Label>
              <br></br>
              <Label className="fw-bold">
                Fecha de impresion : {moment(new Date()).format("YYYY-MM-DD")}
              </Label>
              <br></br>
              <Label className="fw-bold">Descripcion: {formCompra.dc_descripcion}</Label>
              <br></br>
            </Col>
            <Col xs={12} className="text-center padding-print-body">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center w-300px">Codigo</th>
                    <th className="text-center w-300px">Descripcion</th>
                    <th className="text-center w-300px">Cantidad</th>
                    <th className="text-center w-300px">Precio</th>
                    <th className="text-center w-250px">ITBMS</th>
                    <th className="text-center w-500px">Total</th>
                  </tr>
                </thead>
                <tbody className="tbody-print">
                  {props.map((data, i) => {
                    subtotal =Number(subtotal) + Number(Number(data.dcd_cantidad) * Number(data.dcd_precio));
                    itbms = Number(itbms) + Number(data.dcd_itbms);
                    total = Number( subtotal) + Number(itbms);

                    return (
                      <tr key={i}>
                        <td className="text-center">
                          <Label>{data.art_upc}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.art_descripcion}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.dcd_cantidad}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.dcd_precio}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{Number(data.dcd_itbms).toFixed(2)}</Label>
                        </td>
                        <td className="text-center">
                          <Label className="fw-bold">{Number(data.dcd_total).toFixed(2)}</Label>
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="border-0">
                    <td className="text-left font-weight-bold border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">SUBTOTAL</Label>
                    </td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">{subtotal.toFixed(2)}</Label>{" "}
                    </td>
                  </tr>
                  <tr className="border-0">
                    <td className="text-left font-weight-bold border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">ITBMS</Label>
                    </td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">{itbms.toFixed(2)}</Label>{" "}
                    </td>
                  </tr>
                  <tr className="border-0">
                    <td className="text-left font-weight-bold border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">TOTAL</Label>
                    </td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">{total.toFixed(2)}</Label>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
   class ComponentToPrintsCotizacion extends React.Component {

    render() {
      var flagImg=false;
      var props = this.props.props;
      var form = this.props.form;
      var dataCompania = this.props.dataCompania;
      var subtotal = 0;
      var itbms = 0;
      var total = 0;
      props.find((data,i)=>{
        if(data.art_img==="1"){
          flagImg=true;
        return true
        }

      })
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report font-size14">
            <Col xs={2} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
            <Col xs={10} className="text-right title-reporte">
                    <Label className="font-weight-bold">
                      {dataCompania.com_nombre}
                    </Label><br></br>
                    <Label className="font-weight-bold">
                    {"RUC: "+dataCompania.com_ruc + " DV "+dataCompania.com_dv}
                    </Label><br></br>
                    <Label>{dataCompania.com_direccion}</Label><br></br>
                    <Label >
                    {
                    dataCompania.com_telefono2.length>0?dataCompania.com_telefono+" / "+dataCompania.com_telefono2:dataCompania.com_telefono
                    }
                    </Label><br></br>
                    <Label>
                    {dataCompania.com_email}
                    </Label>
                  </Col>
                  <Col xs={12} className="mt-2 mb-2 title-reporte3">
                  <Label className="text-center fw-bolder">COTIZACIÓN </Label>
                  </Col>
            <Col xs={8} className="text-start padding-print-body title-reporte2">
              
              <Label className="fw-bolder">COTIZACIÓN N° {form.cot_id.toString().padStart(7, "0")}</Label>
              <br></br>
              <Label>Cliente : {form.cot_nombre}</Label>
              <br></br>
              <Label>TEL : {form.cli_telefono}</Label>
              <br></br>
              <Label>RUC: {form.cli_ruc}</Label>
            </Col>
            <Col xs={4} className=" title-reporte padding-print-body">
            <br></br>
            <br></br>
              <Label>
                Fecha:  {form.cot_fecha}
              </Label>
              <br></br>
              {/* <Label className="fw-bold">Descripcion: {form.cot_descripcion}</Label> */}
              <Label>Elaborado por: {form.cot_vendedor_name}</Label>
            </Col>
            <Col xs={12} className="text-center padding-print-body">
              <table className="table table-bordered margin-bb-u">
                <thead>
                  <tr>
                    { dataCompania.com_cot_image==="1"?flagImg?<th className="text-center w-300px">Imagen</th>:null:null}
                    <th className="text-center w-300px">Código</th>
                    <th className="text-center w-300px">Descripción</th>
                    <th className="text-center w-300px">Precio</th>
                    <th className="text-center w-300px">Cantidad</th>
                    <th className="text-center w-250px">ITBMS</th>
                    <th className="text-center w-500px">Total</th>
                  </tr>
                </thead>
                <tbody className="tbody-print">
                  {props.map((data, i) => {
                    subtotal =Number(subtotal) + Number(Number(data.cotd_cantidad) * Number(data.cotd_precio));
                    itbms = Number(itbms) + roundNumber(Number(data.cotd_itbms),3);
                    total = Number(subtotal) + Number(itbms);
                    return (
                      <tr key={i} className={ i % 2 === 0 ? "bg-f5": ""}>
                        {dataCompania.com_cot_image==="1"?flagImg? <td className="text-center">
                          {data.art_img==="1"? <img className="img-art-datatable-cotizacion"  src ={imgURLocalImage+data.art_imgName} />:null}
                        </td>:null:null}
                       
                        <td className="text-center">
                          <Label>{data.art_upc}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.art_descripcion}</Label>
                        </td>
                        <td className="text-center">
                          <Label>$ {Number(data.cotd_precio).toFixed(2)}</Label>
                        </td>
    
                        <td className="text-center">
                          <Label>{data.cotd_cantidad}</Label>
                        </td>
                        <td className="text-center">
                          <Label>$ {Number(data.cotd_itbms).toFixed(3)}</Label>
                        </td>
                        <td className="text-center">
                          <Label className="fw-bold">$ {Number(data.cotd_total).toFixed(3)}</Label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
                    <Row className="mt-2">
                    <Col xs={9} className="title-reporte2">
                     <pre>
                               {dataCompania.com_cotizacion_info}
                                </pre>
                    {/* <p className="text-left">
              {dataCompania.com_cotizacion_info}
            </p> */}
                    </Col>
                    <Col xs={3} className="title-reporte5">
                      <Label className="fw-bold margin-r15">SUBTOTAL:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  $ {subtotal.toFixed(2)}</Label><br></br>
                      <Label className="fw-bold margin-r15">ITBMS:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     $ {itbms.toFixed(2)}</Label><br></br>
                      <Label className="fw-bold margin-r15">TOTAL: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    {"    $ "+total.toFixed(2)}</Label>   <br></br>
                    </Col>
                  </Row>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsReporteVentas extends React.Component {
    render() {
      var props = this.props.props;
      var form = this.props.objeData;
      var dataReporteVentasFpago = this.props.dataReporteVentasFpago;
      var formCompania = this.props.formCompania;
      var dataCompania = this.props.dataCompania;
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report">
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
            <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
            <Col xs={12} className="text-start">
              <h5 className="text-center ">REPORTE DE VENTAS </h5>
             
            </Col>
            <Col xs={12} className="text-start-r">
            <Label className="fw-bold">
                Fecha :  {form.startDate} -  {form.Endate}
              </Label>
            </Col>
            <Col xs={12} className="text-center padding-print-body">
              <table className="table table-bordered  ">
                <thead>
                  <tr>
                    <th className="text-center">N° DOCUMENTO</th>
                    <th className="text-center">FECHA</th>
                    <th className="text-center">CLIENTE</th>
                    <th className="text-center">VENDEDOR</th>
                    <th className="text-center">CAJA</th>
                    <th className="text-center">F. ANULADO</th>
                    <th className="text-center w-500px">EXENTO</th>
                    <th className="text-center">F. PAGO</th>
                    <th className="text-center">SUBTOTAL</th>
                    <th className="text-center">ITBMS</th>
                    <th className="text-center">TOTAL</th>
                  </tr>
                </thead>
                <tbody className="tbody-print">
                  {props.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">
                          <Label>{data.fac_id.toString().padStart(10, "0")}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.fac_fecha}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.cli_nombre}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.usr_nombre}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.fac_caja}</Label>
                        </td>
                        {/* <td className="text-center">
                          <Label>{data.fac_motivo_anulacion}</Label>
                        </td> */}
                        <td className="text-center">
                          <Label>{data.fac_date_anulacion}</Label>
                        </td>
                        {/* <td className="text-center">
                          <Label>{data.fac_anulacion !=="1"?"":"ANULADO"}</Label>
                        </td> */}
                        <td className="text-center">
                          <Label>{data.fcd_itbms_code==="00"?"Exento":null}</Label>
                        </td>
                        <td className="text-center">
                                      {
                                      dataReporteVentasFpago.map((datap,i)=>{
                                          if(data.fac_id===datap.fac_id){
                                            return <><Label className="label-fpago">&nbsp;&nbsp;&nbsp;{datap.fcfp_fpago}{": "}{datap.fcfp_monto}</Label></>
                                          }
                                      })
                                      }
                                    </td>
                        <td className="text-center">
                          <Label>{data.fcd_precio}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{Number(data.fcd_itbms).toFixed(2)}</Label>
                        </td>
                       
                        <td className="text-center">
                          <Label>{data.fcd_total}</Label>
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="border-0">
                    <td className="text-left font-weight-bold border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    {/* <td className="text-center"></td> */}
                    <td className="text-center border-0"></td>
   
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-left border-0">
                      <Label className="fw-bold">SUBTOTAL</Label>
                    </td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">{Number(form.subtotal).toFixed(2)}</Label>{" "}
                    </td>
                  </tr>
                  <tr className="border-0 border-0">
                    <td className="text-left font-weight-bold border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                 
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    {/* <td className="text-center"></td> */}
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-left border-0">
                      <Label className="fw-bold">EXENTO ITBMS</Label>
                    </td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">{form.exento.toFixed(2)}</Label>{" "}
                    </td>
                  </tr>
                  <tr className="border-0">
                    <td className="text-left font-weight-bold border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                 
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    {/* <td className="text-center"></td> */}
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-left border-0">
                      <Label className="fw-bold">ITBMS</Label>
                    </td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">{form.itbms.toFixed(2)}</Label>{" "}
                    </td>
                  </tr>
                  <tr className="border-0">
                    <td className="text-left font-weight-bold border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
    
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    <td className="text-center border-0"></td>
                    {/* <td className="text-center"></td> */}
                    <td className="text-center border-0"></td>
                    <td className="text-left border-0 ">
                      <Label className="fw-bold">TOTAL</Label>
                    </td>
                    <td className="text-center border-0">
                      <Label className="fw-bold">{form.total.toFixed(2)}</Label>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col>
            <Label className="text-left">
              {/* {dataCompania.com_cotizacion_info} */}
            </Label>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsReporteIC extends React.Component {
    render() {
      var formCompania = this.props.formCompania;
      var dataReporteInformeCaja = this.props.dataReporteInformeCaja;
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report">
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
  <Col xs={12}>
  <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
  <>
                        <Row>
                          <Col xs={12} className="text-center mt-3">
                          <h5 className="text-center ">REPORTE DE INFORMES DE CAJA</h5>
                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Ventas registradas</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead  dark>
                                <tr>
                                  <th>Forma de pago</th>
                                  <th>Monto</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.montos_fpago.map((data, i) => {
                                    return <tr>
                                      <th>{data.fcfp_fpago}</th>
                                      <td>{data.fcfp_monto}</td>
                                    </tr>
                                  })
                                }

                              </MDBTableBody>
                            </MDBTable>

                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Total de exentos de ITBMS</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Total</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.exentos.map((data, i) => {
                                    return <tr>
                                      <td>{data.fcd_total}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Anulaciones registradas</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Vendedor</th>
                                  <th>Numero de documento</th>
                                  <th>Emitido</th>
                                  <th>Anulado</th>
                                  <th>Total</th>
                                  <th>Motivo</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.anulacion.map((data, i) => {
                                    return <tr>
                                      <th>{data.usr_nombre}</th>
                                      <td>{data.fac_id}</td>
                                      <th>{data.fac_fecha}</th>
                                      <td>{data.fac_date_anulacion}</td>
                                      <th>{data.fcd_total}</th>
                                      <td>{data.fac_motivo_anulacion}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                          <Col xs={12} className="text-center">
                            <Label className="report-title">Totales</Label>
                            <MDBTable striped className="custom-table">
                              <MDBTableHead  dark>
                                <tr>
                                  <th>Ventas subtotal</th>
                                  <th>Ventas con exento</th>
                                  <th>ITBMS</th>
                                  <th>Total</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteInformeCaja.montos_totales.map((data, i) => {
                                    return <tr>
                                      <th>{data.fcd_precio}</th>
                                      <th>{Number(dataReporteInformeCaja.exentos[0].fcd_total).toFixed(2)}</th>
                                      <td>{Number(data.fcd_itbms).toFixed(2)}</td>
                                      <th>{data.fcd_total}</th>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                        </Row>
                      </>
  </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsRecuento extends React.Component {
    render() {
      var props = this.props.props;
      var formRecuento = this.props.formRecuento;
      var formCompania = this.props.formCompania;
      var n_articulo = 0;
      return (
        <React.Fragment>
          <Row key={new Date().toString()}>
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
            <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
            <Col xs={12} className="text-start padding-print-body mt-3">
              <h5 className="fw-bold text-center ">Inventario </h5>
              <Label className="fw-bold">Inventario #: {formRecuento.re_id}</Label>
              <br></br>
              <Label className="fw-bold">
                Fecha de impresion : {moment(new Date()).format("YYYY-MM-DD")}
              </Label>
              <br></br>
              <Label className="fw-bold">Descripcion: {formRecuento.re_descripcion}</Label>
              <br></br>
            </Col>

            <Col xs={12} className="text-center padding-print-body">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center w-300px">Codigo</th>
                    <th className="text-center w-300px">Articulo</th>
                    <th className="text-center w-300px">Conteo</th>
                  </tr>
                </thead>
                <tbody className="tbody-print">
                  {props.map((data, i) => {
                    n_articulo = Number(n_articulo) + 1
                    return (
                      <tr key={i}>
                        <td className="text-center">
                          <Label>{data.art_upc}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.art_descripcion}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.red_cantidad}</Label>
                        </td>
                      </tr>
                    );
                   
                  })}
                  <tr>
                    <td className="text-left font-weight-bold"></td>
                    <td className="text-center">
                      <Label className="fw-bold"># ARTICULOS</Label>
                    </td>
                    <td className="text-center">
                      <Label className="fw-bold">{n_articulo}</Label>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsRecuentoMoviento extends React.Component {
    render() {
      var props = this.props.props;
      var formCompania = this.props.formCompania;
      return (
        <React.Fragment>
          <Row key={new Date().toString()}>
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
  
            <Col xs={12} className="text-start padding-print-body">
              <h3 className="fw-bold text-center ">Movimiento </h3>
              <Label className="fw-bold">
                Fecha de impresion : {moment(new Date()).format("YYYY-MM-DD")}
              </Label>
              <br></br>
            </Col>
            <Col xs={12} className="text-center padding-print-body">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th className="text-center w-300px">Codigo</th>
                    <th className="text-center w-300px">Descripcion</th>
                    <th className="text-center w-300px">Categoria</th>
                    <th className="text-center w-300px">Marca</th>
                    <th className="text-center w-300px">Cantidad en existencia</th>
                  </tr>
                </thead>
                <tbody className="tbody-print">
                  {props.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">
                          <Label>{data.art_upc}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.art_descripcion}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.art_categoria}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.art_marca}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.art_linea_roja}</Label>
                        </td>
                        <td className="text-center">
                          <Label>{data.inv_qty}</Label>
                        </td>
                      </tr>
                    );
                   
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsReporteVM extends React.Component {
    render() {
      var formCompania = this.props.formCompania;
      var dataReportVentasMensuales = this.props.dataReportVentasMensuales;
      var startDate = this.props.startDate;
      var endDate = this.props.endDate;
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report">
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
  <Col xs={12}>
  <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
  <>
                        <Row>
                          <Col xs={12} className="text-center mt-3">
                          <h5 className="text-center ">REPORTE DE VENTAS MENSUALES</h5>
                          </Col>
                          <Col  xs={12} className="text-start">
                          <Label className="report-title">{ <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(endDate).format("YYYY-MM-DD")}
                      </p>}</Label></Col>
                          <Col xs={12} className="text-left">
              
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>Mes</th>
                                  <th>ITBMS</th>
                                  <th>Monto</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReportVentasMensuales.map((data, i) => {
                                    return <tr>
                                      <th>{data.label}</th>
                                      <th>{data.itbms}</th>
                                      <td>{data.result}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                        </Row>
                      </>
  </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsReportePVM extends React.Component {
    render() {
      var dataReporProductosVendidos = this.props.dataReporProductosVendidos;
      var startDate = this.props.startDate;
      var endDate = this.props.endDate;
      var formCompania = this.props.formCompania;
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report">
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
  <Col xs={12}>
  <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
  <>
                        <Row>
                          <Col xs={12} className="text-center mt-4">
                          <h5 className="text-center ">REPORTE DE PRODUCTOS MÁS VENDIDOS</h5>
                          </Col>
                          <Col  xs={12} className="text-start">
                          <Label className="report-title">{ <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(endDate).format("YYYY-MM-DD")}
                      </p>}</Label></Col>
                          <Col xs={12} className="text-left">
              
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th>UPC</th>
                                  <th>Artículo</th>
                                  <th>Marca</th>
                                  <th>Categoría</th>
                                  <th>Cantidad</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporProductosVendidos.map((data, i) => {
                                    return <tr>
                                       <td>{data.art_upc}</td>
                                      <td>{data.art_descripcion}</td>
                                      <th>{data.mar_descripcion}</th>
                                      <th>{data.cat_descripcion}</th>
                                      <td>{data.cantidad}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                        </Row>
                      </>
  </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsReporteUM extends React.Component {
    render() {
      var dataReportUtilidadMargen = this.props.dataReportUtilidadMargen;
      var startDate = this.props.startDate;
      var endDate = this.props.endDate;
      var formCompania = this.props.formCompania;
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report">
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
  <Col xs={12}>
  <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
  <>
                        <Row>
                          <Col xs={12} className="text-center mt-3">
                          <h5 className="text-center ">REPORTE DE UTLIDAD Y MARGEN</h5>
                          </Col>
                          <Col  xs={12} className="text-start">
                          <Label className="report-title">{ <p className="date-p-label float-right-btn">Fecha : {" "}
                        {moment(startDate).format("YYYY-MM-DD")} {" - "}
                        {moment(endDate).format("YYYY-MM-DD")}
                      </p>}</Label></Col>
                          <Col xs={12} className="text-left">
              
                            <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                <th>CÓDIGO</th>
                                <th>Artículo</th>
                                <th>CANTIDAD</th>
                                <th>COSTO</th>
                                <th>TOTAL</th>
                                <th>UTILIDAD</th>
                                <th>MARGEN</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReportUtilidadMargen.map((data, i) => {
                                    return <tr>
                                      <td>{data.art_upc}</td>
                                      <td>{data.art_descripcion}</td>
                                      <th>{Number(data.fcd_cantidad).toFixed(2)}</th>
                                      <th>{Number(data.art_costo).toFixed(2)}</th>
                                      <td>{Number(data.art_total).toFixed(2)}</td>
                                      <td>{(Number(data.art_total)-Number(data.art_costo)).toFixed(2)}</td>
                                      <td>{((Number(data.art_total)-Number(data.art_costo))/Number(data.art_total)*100).toFixed(2)+"%"}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>
                          </Col>
                        </Row>
                      </>
  </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  class ComponentToPrintsBarcode extends React.Component {
    render() {
      var formArticulos = this.props.formArticuloss;
      return (
        <Row className="barcode-box">
          <Col xs={12} className="text-center">
          <ReactBarcode value={formArticulos.art_upc} options={{format: 'code128',textPosition:"top",text:formArticulos.art_upc, width:2,height:35, textMargin:1,fontSize:16,margin:0 }} renderer="svg"/>
         <Label className="barcode-text">{formArticulos.art_descripcion}</Label> <br></br>
         <Label className="barcode-text2">{"$"+formArticulos.art_precio}</Label> 
          </Col>
        </Row>
        
      );
    }
  }
  class ComponentToPrintsReporteCreditos extends React.Component {
    render() {
      var formCompania = this.props.formCompania;
      var dataReporteCreditos = this.props.dataReporteCreditos;
      var dataReporteCreditosPago = this.props.dataReporteCreditosPago;
      return (
        <React.Fragment>
          <Row key={new Date().toString()} className="datatable-report">
            <Col xs={12} className="text-center">
              <img alt="" className="width-logo" src={LOGO}></img>
            </Col>
  <Col xs={12}>
  <Col xs={12} className="text-center mt-4 mb-2">
                    <h3 className="font-weight-bold">
                      {formCompania.com_nombre}
                    </h3>
                    <h7>{formCompania.com_direccion}</h7><br></br>
                    <h7 className="font-weight-bold">
                    {"RUC: "+formCompania.com_ruc + " DV "+formCompania.com_dv}
                    </h7><br></br>
                    <h7 className="font-weight-bold">
                    {"TEL: "+formCompania.com_telefono}
                    </h7>
                  </Col>
  <>
                        <Row>
                          <Col xs={12} className="text-center mt-3">
                          <h5 className="text-center ">REPORTE DE CRÉDITOS</h5>
                          </Col>
                          <Col xs={12} className="text-center">
                          <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th># FACTURA</th>
                                  <th>CLIENTE</th>
                                  <th>RUC</th>
                                  <th>MONTO</th>
                                  <th>FECHA</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteCreditos.map((data, i) => {
                                    return <tr>
                                      <th>{data.fac_id.toString().padStart(10, "0")}</th>
                                      <td>{data.cli_nombre}</td>
                                      <th>{data.cli_ruc}</th>
                                      <td>{data.crd_monto}</td>
                                      <th>{data.crd_fecha}</th>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>

                          </Col>
                          <Col xs={12} className="text-center mt-3">
                          <h5 className="text-center ">REPORTE DE PAGO A CRÉDITOS</h5>
                          </Col>
                          <Col xs={12} className="text-center">
                          <MDBTable striped className="custom-table">
                              <MDBTableHead dark>
                                <tr>
                                  <th># FACTURA</th>
                                  <th>CLIENTE</th>
                                  <th>RUC</th>
                                  <th>MONTO</th>
                                  <th>FECHA PAGO</th>
                                  <th>ELIMINADO</th>
                                </tr>
                              </MDBTableHead>
                              <MDBTableBody>
                                {
                                  dataReporteCreditosPago.map((data, i) => {
                                    return <tr>
                                      <td>{data.fac_id.toString().padStart(10, "0")}</td>
                                      <td>{data.cli_nombre}</td>
                                      <td>{data.cli_ruc}</td>
                                      <td>{data.crp_monto}</td>
                                      <td>{data.crp_fecha}</td>
                                      <td>{data.crp_fecha_delete}</td>
                                    </tr>
                                  })
                                }
                              </MDBTableBody>
                            </MDBTable>

                          </Col>
                        </Row>
                      </>
  </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
  export {
    ComponentToPrintsReporteCreditos,
    ComponentToPrintss,
    ComponentToPrintsCotizacion,
    ComponentToPrintsReporteVentas,
    ComponentToPrintsReporteIC,
    ComponentToPrintsRecuento,
    ComponentToPrintsRecuentoMoviento,
    ComponentToPrintsReporteVM,
    ComponentToPrintsBarcode,
    ComponentToPrintsReportePVM,
    ComponentToPrintsReporteUM
  }