import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './index.css';
import './Componentes/www/style.css';
import Login from './Login';
import Layout from "./Componentes/Layout";
import reportWebVitals from './reportWebVitals';
import StoreProvider from "./Componentes/Store/Provider";
import RoutesPrivate from "./Componentes/Private";
import {Usuarios} from "./Usuario/Index";
import {Reporte} from "./Reporte/Index";
import {Configuracion} from "./Configuracion/Index";
import {Factura} from "./Factura/Index";
import {Cotizacion} from "./Componentes/Cotizacion/Index";
import {Inventario} from "./Inventario/Index";
import {Articulos} from "./Componentes/Articulo/Index";
import {Apartado} from "./Componentes/Apartado/Index";
import {Home} from "./Home/Index";
import {Cocina} from "./Componentes/Cocina/Index";
import {Contactos} from "./Componentes/Contactos/Index";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter basename="/">
    <StoreProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Cocina" element={<Cocina />} />
        <Route element={<Layout />}>
        <Route path="/user/home" element={<Home />} />
        </Route>
          <Route element={<RoutesPrivate />}>
          
            <Route path="/user/usuario" element={<Usuarios />} />
            <Route path="/user/configuracion" element={<Configuracion />} />
            <Route path="/user/factura" element={<Factura />} />
            <Route path="/user/inventario" element={<Inventario />} />
            <Route path="/user/reporte" element={<Reporte />} />
            <Route path="/user/cotizacion" element={<Cotizacion />} />
            <Route path="/user/articulos" element={<Articulos />} />
            <Route path="/user/apartado" element={<Apartado />} />
            <Route path="/user/clientes" element={<Contactos />} />
          </Route>
          {/* <Route element={<RoutesPrivateAdmin />}>
            <Route path="/user/admin" element={<Admin />} />
          </Route> */}
        
      </Routes>
    </StoreProvider>
  </BrowserRouter>
);

reportWebVitals();
